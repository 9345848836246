<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: false,

      sales: false,
      cellphone: "",
      email: "",
    };
  },
  methods: {
    getSettings() {
      api
        .get("settings")
        .then((response) => {
          if (response.data.status == "success") {
            this.sales = (response.data.franchise.sales == 'active') ? true : false;
            this.cellphone = response.data.franchise.cellphone;
            this.email = response.data.franchise.email;
          }
        })
    },
    saveSettings() {
      this.loading = true;

      api
        .post("settings", {
          sales: this.sales ? "active" : "inactive",
          cellphone: this.cellphone,
          email: this.email,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$toast.success("As configurações foram salvas com sucesso.");
          } else {
            this.$toast.error(response.data.message);
          }

          this.loading = false;
        })
        .catch(() => {
          this.$toast.error("Não foi possível salvar as configurações.");
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSettings();
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Configurações</h3>
        <p></p>
      </div>
    </div>

    <fieldset>
      <div class="row justify-content-center">
        <div class="col-md-6 py-4">
          <b-form-group label="Status" label-for="cellphone" class="mb-5">
            <b-form-checkbox v-model="sales" switch size="lg"> Habilitar as vendas dos produtos</b-form-checkbox>
          </b-form-group>
          <b-form-group label="Celular" label-for="cellphone">
            <b-form-input v-model="cellphone" type="text" autocomplete="off" v-mask="'(##) #####-####'"></b-form-input>
          </b-form-group>
          <b-form-group label="E-mail" label-for="email">
            <b-form-input v-model="email" type="text" autocomplete="off"></b-form-input>
          </b-form-group>
          <div >
            <button class="btn btn-default btn-lg" v-on:click="saveSettings()" :disabled="loading">
              Salvar
              <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </Layout>
</template>